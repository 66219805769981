import type { StipsCollection } from "@/models/common";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

export const useStipsCollection = () => {
  const { t } = useI18n();

  const getCategory = (type: string) => {
    return { type, name: t(`FILE_TYPES.${type}`) };
  };

  return computed<StipsCollection[]>(() =>
    [
      {
        id: 1,
        name: t("STIPS.STIP_COLLECTION.SIGNED_APPLICATION"),
        categories: ["signed_application_doc"]
      },
      {
        id: 2,
        name: t("STIPS.STIP_COLLECTION.BANK_STATEMENTS"),
        categories: [
          "bank_statements_1",
          "bank_statements_2",
          "bank_statements_3",
          "bank_statements_4",
          "bank_statements_5",
          "bank_statements_6",
          "bank_statements_7",
          "bank_statements_8",
          "bank_statements_9",
          "bank_statements_10",
          "bank_statements_11",
          "bank_statements_12"
        ]
      },
      {
        id: 3,
        name: t("STIPS.STIP_COLLECTION.TAX_RETURNS"),
        categories: [
          "tax_return_1",
          "tax_return_2",
          "tax_return_3",
          "tax_return_4",
          "tax_return_5",
          "tax_return_6"
        ]
      },
      {
        id: 4,
        name: t("STIPS.STIP_COLLECTION.AP_DOCUMENTS"),
        categories: [
          "ap_document_1",
          "ap_document_2",
          "ap_document_3",
          "ap_document_4",
          "ap_document_5",
          "ap_document_6"
        ]
      },
      {
        id: 5,
        name: t("STIPS.STIP_COLLECTION.FINANCIAL_DOCUMENTS"),
        categories: [
          "financial_document_1",
          "financial_document_2",
          "financial_document_3",
          "financial_document_4",
          "financial_document_5",
          "financial_document_6"
        ]
      },
      {
        id: 6,
        name: t("STIPS.STIP_COLLECTION.OFFER_STIPS"),
        categories: ["offer_stip"]
      },
      {
        id: 7,
        name: t("STIPS.STIP_COLLECTION.CONTRACTS"),
        categories: ["contract"]
      },
      {
        id: 8,
        name: t("STIPS.STIP_COLLECTION.PLAID_STATEMENTS"),
        categories: ["plaid_statement"]
      },
      {
        id: 9,
        name: t("STIPS.STIP_COLLECTION.APPLICATION_STIPS"),
        categories: ["application_stip"]
      },
      {
        id: 10,
        name: t("STIPS.STIP_COLLECTION.VERIFICATION_DOCUMENTS"),
        categories: ["verification_doc"]
      },
      {
        id: 11,
        name: t("STIPS.STIP_COLLECTION.CFA_DATA"),
        categories: ["raw_cfa_data"]
      },
      {
        id: 12,
        name: t("STIPS.STIP_COLLECTION.TAX_EXEMPTIONS"),
        categories: ["tax_exemption"]
      },
      {
        id: 13,
        name: t("STIPS.STIP_COLLECTION.INSURANCE_CERTIFICATES"),
        categories: ["insurance_certificate"]
      },
      {
        id: 14,
        name: t("STIPS.STIP_COLLECTION.FUNDING_INVOICE"),
        categories: ["funding_invoice"]
      },
      {
        id: 15,
        name: t("STIPS.STIP_COLLECTION.RECOVERED_DOCUMENT"),
        categories: ["recovered_document"]
      },
      {
        id: 16,
        name: t("STIPS.STIP_COLLECTION.PAYROLL_REPORT"),
        categories: [
          "payroll_report",
          "payroll_report_1",
          "payroll_report_2",
          "payroll_report_3",
          "payroll_report_4",
          "payroll_report_5",
          "payroll_report_6",
          "payroll_report_7"
        ]
      },
      {
        id: 17,
        name: t("STIPS.STIP_COLLECTION.DEBT_SCHEDULE"),
        categories: ["debt_schedule"]
      },
      {
        id: 18,
        name: t("STIPS.STIP_COLLECTION.DRIVERS_LICENCE"),
        categories: ["drivers_license_front", "drivers_license_back"]
      },
      {
        id: 19,
        name: t("STIPS.STIP_COLLECTION.OTHER"),
        categories: [
          "risk_inform_report",
          "iso_application",
          "adverse_media_report",
          "inquiries",
          "xlsx_cfa_data"
        ]
      }
    ].map(({ categories, ...rest }) => ({
      ...rest,
      categories: categories.map(getCategory)
    }))
  );
};
